import { Exclude, Expose, instanceToPlain } from 'class-transformer';
import { uuidv7 } from 'uuidv7';

import { TimesheetSupportItem } from '../interfaces/timesheet-expense-v3.interface';
import { HasDeletedFlag } from '../interfaces/has-deleted-flag.interface';

@Exclude()
export class TimesheetExpenseClass implements TimesheetSupportItem, HasDeletedFlag {
    @Expose() public amount: number = 0;
    @Expose() public description: string = '';
    @Expose() public id: string;
    @Expose() public isDeleted: boolean = false;
    @Expose() public note: string;
    @Expose() public placementExpenseId: number = null;
    @Expose() public placementId: number;
    @Expose() public quantity: number;
    @Expose() public timesheetHourId: string;
    @Expose() public timesheetId: string;
    @Expose() public type: string = null;

    public isCreated: boolean = false;
    public isSaved: boolean = false;

    constructor() {
        this.id = uuidv7();
    }

    public getDto(): TimesheetExpenseClass {
        const exposeDtoOptions = { excludeExtraneousValues: true, exposeUnsetFields: true };

        return instanceToPlain(this, exposeDtoOptions) as TimesheetExpenseClass;
    }

    public bind(input: TimesheetSupportItem): TimesheetExpenseClass {
        this.placementId = input.placementId;
        this.timesheetHourId = input.timesheetHourId;
        this.timesheetId = input.timesheetId;

        return this;
    }

    public markDeleted(): TimesheetExpenseClass {
        this.isDeleted = true;

        return this;
    }

    public markSaved(): TimesheetExpenseClass {
        this.isSaved = true;

        return this;
    }

    public markCreated(): TimesheetExpenseClass {
        this.isCreated = true;

        return this;
    }

}
