import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformErrorService } from './inform-error.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        InformErrorService,
    ],
})
export class InformErrorModule { }
