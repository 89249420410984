// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import versionConfig from '../../../../package.json';

export const environment = {
    production: false,
    apiURL: 'https://andev.actassa.com/api',
    botURL: 'wss://bots.activeassociate.com/dev/webchat001/',
    firebase: {
        apiKey: 'AIzaSyAQ4oslU4Zt4kRKkIlYeHvn3cTCe3VpOic',
        authDomain: 'alliance-nursing.firebaseapp.com',
        projectId: 'alliance-nursing',
        storageBucket: 'alliance-nursing.appspot.com',
        messagingSenderId: '523259932090',
        appId: '1:523259932090:web:c6329d1dcb68a70990c183',
        measurementId: 'G-BLW8MQ9334',
    },
    socketIoConfig: {
        url: 'https://andev.actassa.com',
        options: {
            autoConnect: true,
            path: '/messaging',
        },
    },
    appVersion: versionConfig.version,
    appId: 'com.actassa.alliancenursing',
    appName: 'Alliance Nursing',
    appleAppId: '1543990033',
    platform: 'web',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
